<template>
  <div class="chinaMap">
    <div class="angle1"></div>
    <div class="angle2"></div>
    <div class="angle3"></div>
    <div class="angle4"></div>
  </div>

</template>

<script>
import "echarts/map/js/province/hunan.js"

export default {
  name: "",
  data(){
    return{
    }
  },
  created() {
  },
  mounted() {
    this.setMap();
  },
  watch:{
    // seriesData.data.list[params.value]: {
    //   handler(value) {
    //     console.log(value,'value')
    //     // this.setMap();
    //   },
    //   immediate: true
    // }
  },
  methods:{
    setMap() {
      let options = {
        title: {
          text: '',
          subtext: '',
          x: 'center'
        },
        legend: {
          orient: 'vertical',
          x: 'left',
          data: ['']
        },
        // dataRange: {
        //   min: 0,
        //   max: 1000,
        //   x: 'left',
        //   y: 'bottom',
        //   text: ['max', 'min'],
        //   calculable: true
        // },
        toolbox: {
          show: false,
          orient: 'vertical',
          x: 'right',
          y: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        roamController: {
          show: true,
          x: 'right',
          mapTypeControl: {
            'china': true
          }
        },
        // dataRange: { //左下角的颜色块。start：值域开始值；end：值域结束值；label：图例名称；color：自定义颜色值
        //   x: 'left',
        //   y: 'bottom',
        //   show:false,
        //   splitList: [
        //     {start: 0.1, label: '有数据', color: '#1a50af'},
        //     {start:0, end: 0,label: '无数据', color: '#3d60b4'}
        //   ]
        // },
        series: [{
          name: '',
          type: 'map',
          mapType: '湖南',
          roam: false,
          zoom:1.2,
          showLegendSymbol:false,
          label: {
            normal: {
              show: true,
              color:'#fff',
              fontStyle:'italic',
              fontWeight:'bold',
              verticalAlign:'bottom',
              // offset:[30,40],
              fontSize:16
            }
          },
          itemStyle: {
            normal: {
              borderColor: '#26deee', //区域边框颜色
              areaColor: '#2452a9', //区域填充颜色
            },
            emphasis: {
              areaColor: 'rgb(57,173,236)',
              borderWidth:2,
            }
          },
          data:this.seriesData
        }],

      }

      let myChart = this.$echarts(this.$el);

      // myChart.clear();
      // myChart.resize()
      myChart.setOption(options);
    },

  }
}
</script>

<style lang="less" scoped>
.chinaMap {
  width:100%;
  height:100%;
  border: 1px solid #0D2451;
  position: relative;
  .angle1{
    top: 0;
    left: 0;
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 1px solid #1C5AB3;
    border-left: 1px solid #1C5AB3;
  }
  .angle2{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    right: 0;
    border-top: 1px solid #1C5AB3;
    border-right: 1px solid #1C5AB3;
  }
  .angle3{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #1C5AB3;
    border-left: 1px solid #1C5AB3;
  }
  .angle4{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid #1C5AB3;
    border-right: 1px solid #1C5AB3;
  }
}

</style>